import React, { useEffect } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { StyleRegister } from './index.styled';

const RegisterForm: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
          region: 'na1',
          formId: process.env.GATSBY_HUBSPOT_FORM_ID,
          target: '#hubspotForm',
        });
      }
    });
  }, []);

  return (
    <StyleRegister>
      <div>
        <h1>Sumá tu comercio a Buba</h1>
        <div className="form-paragraph">
          <p>Te avisaremos cuando puedas comenzar a buscar empleados en Buba</p>
          <AnchorLink to="/empleados/registro/">
            <p className="form-switch">¿Estás buscando empleo?</p>
          </AnchorLink>
        </div>
      </div>
      <div id="hubspotForm"></div>
    </StyleRegister>
  );
};

export default RegisterForm;
