import React from 'react';
// @ts-ignore
import { Helmet } from 'react-helmet';
import Nav from '@components/common/Nav';
import RegisterForm from '@components/sections/RegisterForm'
import Footer from '@components/sections/Footer';
import Layout from '../../layouts';

const Register: React.FC = () => {
  

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="BubaJobs | Portal de empleo en Argentina" />
        <title>BubaJobs | Portal de empleo en Argentina</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Nav activeSection={"none"} />
      <RegisterForm />
      <Footer />
    </Layout>
  );
};

export default Register;
